/********************************************************************************************************
* Gradients
********************************************************************************************************/

/* Tenet-brand light linear gradient */
$gradient-linear-light: linear-gradient(
  94.64deg,
  #dbf4fd -22.2%,
  #e8d2ff 105.47%
);

$gradient-linear-blue: linear-gradient(94.64deg, #bbecff 48%, #1691b999 60%);

/* Tenet-brand dark linear gradient */
$gradient-linear-dark: radial-gradient(
    100% 5074.71% at 0% 0%,
    rgba(241, 251, 255, 0.08) 0%,
    rgba(4, 20, 32, 0) 100%
  ),
  linear-gradient(98.41deg, #24272c 0%, #02141a 100%);

$gradient-linear-dark-inverse: radial-gradient(
    100% 5074.71% at 0% 0%,
    rgba(241, 251, 255, 0.08) 100%,
    rgba(4, 20, 32, 0) 0%
  ),
  linear-gradient(98.41deg, #24272c 0%, #02141a 100%);

/* electric purple mesh gradient */
$gradient-mesh-purple-electric: radial-gradient(
      26.64% 82.45% at 29.44% 0%,
      rgba(255, 240, 251, 0.14) 0%,
      rgba(84, 9, 159, 0.12) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(94.8deg, #b570ff 0%, #970075 100%);

/* electric blue mesh gradien */
$gradient-mesh-blue-electric: radial-gradient(
      22.43% 85.48% at 47.66% -12.9%,
      rgba(240, 255, 250, 0.16) 0%,
      rgba(9, 159, 24, 0.16) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(94.8deg, #c5f0ff 0%, #0aa7ff 100%);

/* light purple mesh gradient */
$gradient-mesh-purple-pale: radial-gradient(
      83.87% 83.87% at 47.66% -12.9%,
      rgba(255, 136, 223, 0.14) 0%,
      rgba(125, 10, 239, 0.12) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(94.8deg, #f0e1ff 0%, #9d8bae 100%);

/* light blue mesh gradient */
$gradient-mesh-blue-pale: radial-gradient(
      83.87% 83.87% at 47.66% -12.9%,
      rgba(162, 249, 255, 0.2) 0%,
      rgba(16, 130, 194, 0.12) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(94.8deg, #cfe2ff 0%, #618fba 100%);

$gradient-blue-purple: radial-gradient(
      83.87% 83.87% at 47.66% -12.9%,
      rgba(162, 249, 255, 0.2) 0%,
      rgba(16, 130, 194, 0.12) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(94.8deg, #dbf4fd 0%, #e8d2ff 100%);
