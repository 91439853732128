@use "#v2-components/atoms/colors" as c;
@use "#v2-components/atoms/typography" as t;
@use "#v2-components/molecules/text.module" as text;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;

@keyframes translateX {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(4px);
  }
}

.linkedTileCard {
  border: 1px solid c.$charcoal-200;
  box-shadow: 0px 1px 4px rgba(56, 57, 58, 0.1),
    0px 1px 2px -1px rgba(56, 57, 58, 0.1);
  border-radius: 8px;
  height: 100%;
  width: fit-content;
  transition: all 0.3s;

  &:hover,
  &:focus {
    box-shadow: 0px 10px 15px -3px rgba(56, 57, 58, 0.1),
      0px 4px 6px -4px rgba(56, 57, 58, 0.1);
    border-color: c.$charcoal-300;

    img {
      scale: 1.05;
    }

    svg {
      animation-duration: 0.3s;
      animation-name: translateX;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }

  .imageContainer {
    overflow: hidden;

    img {
      object-fit: contain;
      transition: scale 0.3s;
    }
  }

  .mainContent {
    h3 {
      @include text.header-4();
    }

    p {
      @include text.body-1();
    }

    .buttonContainer {
      align-self: flex-end;

      @include breakpoint.display-to("s") {
        &.center {
          align-self: center;
        }

        &.alignLeft {
          align-self: flex-start;
        }

        &.alignRight {
          align-self: flex-end;
        }
      }
    }

    &:hover,
    &:focus {
      svg {
        animation-duration: 0.3s;
        animation-name: translateX;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }
  }
}

.sideBySide {
  display: flex;
  width: 100%;
  max-height: 350px;
  overflow: hidden;

  @include breakpoint.display-to("s") {
    p {
      display: none;
    }

    @include breakpoint.display-to("s") {
      max-height: 175px;
    }
  }

  .imageContainer {
    width: 45%;
    max-height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .mainContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 55%;
    padding: 24px 16px;

    .cardDetails {
      h3 {
        @include text.body-1();
      }

      &:has(p) {
        @include breakpoint.display-from("s") {
          h3 {
            @include text.header-4();
          }
        }
      }

      p {
        @include text.body-1();
      }
    }
  }
}

.aboveAndBelow {
  display: flex;
  flex-direction: column;
  width: 100%;

  .imageContainer {
    max-height: 240px;
    border-radius: 6px 6px 0 0px;

    @include breakpoint.display-to("xs") {
      display: none;
    }

    img {
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .mobileImageContainer {
    display: none;
    border-radius: 6px 6px 0 0px;
    overflow: hidden;

    img {
      object-fit: cover;
      transition: scale 0.3s;
      width: 100%;
    }

    @include breakpoint.display-to("xs") {
      display: block;
    }
  }

  .mainContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;

    @include breakpoint.display-to("m") {
      flex-direction: column;
      align-items: flex-start;

      p {
        margin: 8px 0 12px 0;
      }
    }
  }
}

.promotional {
  padding: 24px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  height: auto;

  &:hover,
  &:focus {
    img {
      scale: unset;
    }
  }

  .imageContainer {
    img {
      width: 100%;
      max-width: 400px;
    }
  }

  .cardDetails {
    p {
      margin: 8px 0;
    }
  }

  .buttonContainer {
    margin: 16px 0;

    @include breakpoint.display-to("m") {
      & > div:only-child {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin: 16px 0;

    span {
      white-space: nowrap;
    }

    @include breakpoint.display-to("m") {
      width: 100%;

      & > div:only-child {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }

    .centerDivider {
      border-right: 1px solid c.$charcoal-400;
      height: 24px;
    }
  }
}

.imageFocus {
  width: 100%;
  display: flex;

  @include breakpoint.display-to("s") {
    max-height: 175px;
  }

  @include breakpoint.display-from("s") {
    flex-direction: column;
  }

  .imageContainer {
    height: 100%;
    border-radius: 8px 8px 0 0px;

    @include breakpoint.display-to("s") {
      width: 45%;
      height: unset;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .mainContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    flex-grow: 1;

    @include breakpoint.display-to("m") {
      flex-direction: column;
    }

    h3 {
      @include text.body-1();

      @include breakpoint.display-to("s") {
        text-align: left;
      }
    }

    .cardDetails {
      align-self: flex-start;
    }
  }
}

.v5wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;

  h3 {
    /* Title/H1/SemiBold */
    @include text.header-1();
    color: c.$purple-medium;
    font-weight: 600;
    line-height: 58px; /* 120.833% */
    margin-bottom: 24px;
  }

  p {
    /* Title/H3/Regular */
    @include text.header-4();
    line-height: 36px; /* 128.571% */
  }

  button {
    margin-top: 24px;
  }
}
