@use "#v2-components/molecules/breakpoint.module" as breakpoint;

.desktop {
  display: flex;
  width: 100%;
  height: auto;
  @include breakpoint.display-to("xs") {
    display: none;
  }
}
.mobile {
  display: none;
  @include breakpoint.display-to("xs") {
    display: flex;
    width: 100%;
    height: auto;
  }
}
