@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;
@use "#v2-components/molecules/text.module" as text;

.infographicContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: c.$charcoal-100;
  border: 1px solid c.$charcoal-200;
  box-shadow: 0px 1px 4px rgba(56, 57, 58, 0.1),
    0px 1px 2px -1px rgba(56, 57, 58, 0.1);
  border-radius: 8px;
  padding-top: 32px;

  .main {
    width: 55%;
    margin-bottom: 36px;
    position: relative;

    @include breakpoint.display-to("s") {
      width: 90%;
      margin-bottom: 24px;
    }
  }

  .pill {
    display: flex;
    justify-content: space-between;
    width: 120px;
    align-items: center;
    background: #f9f9fb;
    box-shadow: inset 1px 0px 4px rgba(34, 34, 34, 0.4);
    border-radius: 40px;
    position: absolute;
    width: 100%;

    .deferredContainer > .deferred,
    .standardContainer > .standard {
      @include text.body-2();
      cursor: pointer;
      padding: 8px 16px;
    }

    .deferredContainer {
      position: relative;
      height: 36px;
      // width: 70%;

      &.active {
        width: 60%;
      }

      &.inactive {
        width: 40%;
      }

      .deferredBackground {
        position: absolute;
        background: linear-gradient(293.45deg, #651cb4 -0.07%, #a856ff 104.76%);
        box-shadow: 1px 0px 2px rgba(34, 34, 34, 0.24);
        opacity: 0;
        transition: opacity 0.5s;
        height: 100%;
        width: 100%;
        border-radius: 40px;

        &.active {
          opacity: 1;
        }
      }

      .deferred {
        display: flex;
        align-items: center;
        gap: 4px;
        transition-duration: 0.5s;
        transition-property: color;
        background: unset;
        position: absolute;
        transition: transform 0.3s;

        &.inactive {
          color: c.$purple-medium;
          transform: translateX(10%);
        }
        svg {
          transition: fill 0.5s;
          fill: c.$purple-medium;
        }

        &.active {
          color: c.$white-100;
          transform: translateX(35%);

          svg {
            fill: c.$white-100;
          }
        }
      }
    }

    .standardContainer {
      position: relative;
      height: 36px;

      &.active {
        width: 60%;
      }

      &.inactive {
        width: 40%;
      }

      .standardBackground {
        position: absolute;
        right: 0;
        background: linear-gradient(94.98deg, #373241 18.43%, #0e001d 93.28%);
        box-shadow: 1px 0px 2px rgba(34, 34, 34, 0.24);
        opacity: 0;
        transition: opacity 0.5s;
        height: 100%;
        width: 100%;
        border-radius: 40px;

        &.active {
          opacity: 1;
        }
      }

      .standard {
        display: flex;
        align-items: center;
        gap: 4px;
        transition-duration: 0.5s;
        transition-property: color;
        background: unset;
        position: absolute;
        right: 0;
        transition: transform 0.3s;

        &.inactive {
          color: c.$purple-medium;
          transform: translateX(-25%);
        }
        svg {
          transition: fill 0.5s;
          fill: c.$purple-medium;
        }

        &.active {
          color: c.$white-100;
          transform: translateX(-50%);

          svg {
            fill: c.$white-100;
          }
        }
      }
    }
  }

  img {
    width: 100%;
  }

  .breakdownBar {
    background: c.$charcoal-400;
    height: 20px;
    border-radius: 4px;
    position: relative;
    // EV: same as above, but for bottom padding
    margin-top: -10px;

    & > div {
      position: absolute;
      height: 20px;
      transition: max-width 0.3s;
    }
  }

  .termLength {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 8px 0;

    span {
      @include text.disclaimer();
      color: c.$charcoal-400;
      text-align: center;
      padding: 0 8px;
    }

    .leftDashes,
    .rightDashes {
      border: 1px dashed c.$charcoal-300;
      height: 0;
      width: auto;
      flex-grow: 1;
    }
  }

  .breakdown {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-height: 70px;

    & > div {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .box {
      width: 8px;
      height: 8px;
      border-radius: 2px;
    }

    .monthlyPaymentsBreakdown {
      .box {
        background: c.$blue-electric;
      }
      p {
        font-weight: 600;
      }
    }
    .deferredPaymentsBreakdown {
      .box {
        background: c.$purple-electric;
      }
    }
    .lifetimeInterestBreakdown {
      .box {
        background: c.$charcoal-400;
      }
    }

    p {
      @include text.body-2();
    }
  }

  .disclaimer {
    padding: 16px;
    padding-top: 0;
    @include text.disclaimer();
    color: c.$charcoal-400;
  }
}
