@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/text.module" as text;

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorContainer {
  @include text.disclaimer(c.$charcoal-400);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 16px;
  border-radius: 16px;
}

.suberror {
  @include text.disclaimer(c.$charcoal-400);
}
