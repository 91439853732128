@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;
@use "#v2-components/molecules/text.module" as text;
@use "#v2-components/atoms/gradients" as g;

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: g.$gradient-blue-purple;
  border-radius: 8px;
  padding: 16px 24px;
  box-shadow: 0px 1px 4px rgba(56, 57, 58, 0.05),
    0px 1px 2px -1px rgba(56, 57, 58, 0.05);

  h2 {
    @include text.header-4;
    font-weight: bold;
  }

  h3 {
    @include text.body-1;
  }

  p {
    @include text.body-2;
  }
  a {
    text-decoration: underline;
    color: c.$blue-medium;
  }
}

.body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @include breakpoint.display-to("s-m") {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.headerSection {
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    min-height: 52px;
    min-width: 52px;
    fill: white;
  }

  & > div {
    display: flex;
    flex-direction: column;

    @include breakpoint.display-to("s-m") {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  @include breakpoint.display-to("s-m") {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

// ALB: I really needed a circular shadow behind the svg and this was the easiest way :(
.svgWrapper {
  background: white;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  box-shadow: 0px 1px 4px rgba(56, 57, 58, 0.05),
    0px 1px 2px -1px rgba(56, 57, 58, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputContainer {
  background: white;
  border-radius: 28px;
  padding: 12px;
  height: 56px;
  display: flex;
  align-items: center;
  min-width: 324px;
  gap: 8px;
  // justify-content: space-between;
  input {
    @include text.body-1;
    margin-left: 16px;
    width: auto;
    flex: 1 1 auto;
  }

  .subscribeButton {
    flex: 0 1;
  }

  @include breakpoint.display-to("s") {
    width: 100%;
  }
  ::placeholder {
    color: c.$charcoal-400;
  }
  input:focus {
    outline: none;
  }
}

.errorMessage {
  // matches the border-radius
  margin-left: 28px;
  color: c.$red-medium-200 !important;
  margin-top: 4px;
}

.purpleText {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
  color: c.$purple-medium !important;
  svg {
    height: 16px;
    width: 16px;
  }
}

.grayText {
  color: c.$charcoal-500 !important;
}
