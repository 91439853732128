@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;
@use "#v2-components/molecules/text.module" as text;

.container {
  display: flex;
  gap: 48px;
  padding-bottom: 80px;
  min-height: 650px;
  h1 {
    @include text.header-3;
  }
  h2 {
    @include text.header-4;
  }
  &.paddingTop {
    padding-top: 40px;
  }
  @include breakpoint.display-to("m") {
    flex-direction: column;
    gap: 24px;
  }
}
.half {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;

  & > div:last-child {
    @include breakpoint.display-from("m") {
      max-height: 477px;
    }
  }
}

.cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  &:not(:first-child) {
    & > a {
      @include breakpoint.display-to("s") {
        max-height: 150px;
      }
    }
  }

  .cardDetails {
    h3 {
      @include text.body-1();
    }
  }

  @include breakpoint.display-to("s") {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: none;
  }

  & > a:first-child {
    grid-column: 1/2;
    grid-row: 1/3;

    @include breakpoint.display-to("s") {
      grid-column: unset;
      grid-row: unset;
    }
  }

  & > a:nth-child(2) {
    grid-column: 2/3;
    grid-row: 1/2;

    @include breakpoint.display-to("s") {
      grid-row: unset;
      grid-column: unset;
    }
  }

  & > a:last-child {
    grid-column: 2/3;
    grid-row: 2/3;

    @include breakpoint.display-to("s") {
      grid-row: unset;
      grid-column: unset;
    }
  }
}

.text {
  height: 120px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include breakpoint.display-to("s") {
    height: unset;
  }
}
