@use "../../../styles/variables";

.SupportContactCardContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 20px 20px 20px 0px;
  padding: 20px 15px;
  background-color: variables.$background-inverse;
  color: variables.$clr-txt-inv;
}

.supportHeader {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
}
.supportRow {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  height: 30px;
  &:hover {
    color: variables.$clr-txt-inv-2;
  }
}
.frame {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 10px;
}
.supportSubscript {
  align-self: flex-end;
  font-size: 14px;
  line-height: 20px;
  color: #d9d9d9;
}

.readyToApplyContainer {
  display: flex;
  margin: 80px auto;

  max-width: 800px;
  justify-content: space-between;
  box-shadow: 0px 2px 20px #ebebeb;
  border-radius: 20px;
  padding-left: 30px;

  background-color: variables.$clr-bg-1;

  @media screen and (max-width: variables.$screen-small-tablet) {
    flex-direction: column;
    width: 100%;
    padding: 30px;
    margin: 0px;
  }
}

.leftContainer {
  display: flex;
  flex-direction: column;
  margin-right: 120px;
  @media screen and (max-width: variables.$screen-large-tablet) {
    margin-right: 0px;
  }
}

.readyToApplyHeader {
  font-size: 28px;
  line-height: 36px;
  margin-top: 30px;
}

.readyToApplyDesc {
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}

.buttons {
  display: flex;
  flex-direction: row;
  min-height: 100px;
  align-content: center;
  align-items: center;

  @media (max-width: variables.$screen-small-tablet) {
    flex-direction: column;
  }
}

.button {
  height: 40px;
  font-size: 12px;
  width: 175px;
  line-height: 14px;
  margin: 8px 10px 8px 0;
  padding: 0 20px !important;
  @media (max-width: variables.$screen-small-tablet) {
    width: 100%;
  }
}

.leftButton {
  @extend .button;
  background-color: #23a0c8;
  &:hover {
    background-color: #23a0c8cc;
  }
  @media (max-width: variables.$screen-small-tablet) {
    margin: 28px 0px 10px 0px;
  }
}

.rightButton {
  @extend .button;
  background-color: #6fbad733;
  color: #23a0c8;
  &:hover {
    background-color: #6fbad766;
  }
  @media (max-width: variables.$screen-small-tablet) {
    margin: 0px 0px 30px 0px;
  }
}

.contactUs {
  display: flex;
}
