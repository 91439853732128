@use "#v2-components/atoms/colors" as c;
@use "#v2-components/molecules/breakpoint.module" as breakpoint;
@use "#v2-components/molecules/text.module" as text;

.container {
  width: 100%;
  display: flex;
  justify-content: center;

  @include breakpoint.display-from("m") {
    max-width: 100vw;
  }
}

.section {
  max-width: 1440px;
  width: calc(100% - 64px);
  margin: 0 auto;

  &.fullWidth {
    width: 100%;
    max-width: none;
    margin-right: 5px;
    margin-left: 6px;
  }
}
